import {IAuthenticatedEnv, IReg, REG} from "/@lib@/commons/registry.js";
import {BaseElement, OSkinableInit} from "/@back@/commons/basis.js";
import {initLoginForm, initRenewPwdForm} from "/@back@/core/userSelfForms.js";
import {DOM, JSX} from "/@lib@/commons/xml/dom.js";
import {DOMSH} from "/@lib@/commons/xml/domsh.js";
import {IBasicUniversePointer, IUniverseEnv} from "/@lib@/core/universe.js";
import {Action} from "/@lib@/commons/actions.js";
import {POPUP} from "/@back@/commons/widgets/popups.js";
import {UiLangDeskFeat} from "/@back@/core/plugins/optionsPlg.js";
import {ActionBtn, OActionBtnInit} from "/@back@/commons/widgets/buttons.js";
import {UrlHashObjDeskFeat} from "/@lib@/commons/desk.js";

export class LoginDialog extends BaseElement {

	protected _initialize(init: OSkinableInit) {
		const reg = this.findReg<IBasicUniversePointer & IAuthenticatedEnv>(init);
		const sr = this.attachShadow(DOMSH.SHADOWDOM_INIT);
		reg.installSkin("webzone:panel", sr);
		this._initAndInstallSkin(this.localName, init);

		const embeddedLoginForm = reg.getSvc<(reg: IReg<IUniverseEnv & IAuthenticatedEnv>, targetElt: Node) => HTMLFormElement>('fullUserLoginMaker')(reg, sr);

		if (embeddedLoginForm) {
			// Hack pour faire fonctionner l'enregistrement des mots de passe sur Chrome : ajout d'un input password caché en dehors du shadow !
			this.appendChild(<form style="display: none"><input name="password" type="password"/></form>);
			const universe = reg.env.universe;
			initLoginForm(embeddedLoginForm, universe.userSelf, universe.auth, {
				defaultAccount: localStorage.getItem("login:lastAccount"),
				showRenewPwd: () => {
					const renewForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userRenewPwdForm')(reg);
					sr.querySelectorAll("*").forEach(elt => DOM.setHidden(elt, true));
					sr.appendChild(renewForm);
					return renewForm;
				},
				hideRenewPwd: (renewForm) => {
					renewForm.remove();
					sr.querySelectorAll("*").forEach(elt => DOM.setHidden(elt, false));
					embeddedLoginForm.hidden = false;
				}
			});
		}
	}

}

export interface JLoginAppDef<E> {
	/** Code de l'univers à afficher */
	lastRemoteLoginAttempt: E,
	authMethod: string,
}

REG.reg.registerSkin('c-login', 1, /* language=CSS */ `
	:host {
		display: flex;
		min-height: 0;
		min-width: 0;
		padding: 1em;
		flex-direction: column;
	}

	:focus-visible {
		outline: var(--focus-outline);
	}

	.authTypeContainer {
		margin-block-start: 1em;
		border-width: 1px;
		border-color: var(--alt2-border-color);
	}

	.authTypeContainer > legend {
		color: var(--alt2-color);
		font-weight: bold;
	}


	.fields {
		max-width: 30em;
		width: 85vw;
		margin: auto;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-row-gap: 0.5em;
		grid-column-gap: 0.5em;
	}

	label {
		text-align: end;
		color: var(--alt1-color);
		user-select: none;
	}

	input {
		background-color: var(--form-bgcolor);
		color: var(--form-color);
		border: 1px solid var(--border-color);
	}

	input:invalid {
		border: 1px solid var(--error-color);
		box-shadow: 0 0 2px var(--error-color);
	}

	.message {
		margin: 1em;
	  font-style: italic;
	}

	.message[data-type='pending'] {
		text-align: end;
	}

	.error {
		color: var(--error-color);
		margin: 1em;
	}

	#langCtn {
		display: flex;
		justify-content: flex-end;
		margin-bottom: .5em;
	}

	.footer {
		display: flex;
		min-height: 0;
		min-width: 0;
		justify-content: flex-end;
		margin-block: 1em 0;
		margin-inline: 0;
	}

	.spacer {
		flex: 1;
	}

	button {
		padding: 0.5em;
		border-radius: 5px;
		border: 1px solid var(--border-color);
		background-color: var(--bgcolor);
		color: var(--color);
		cursor: pointer;
		margin-block: 0;
		margin-inline: 1em 0;
	}

	button:hover {
		background-color: var(--pressed-bgcolor);
	}

	button[type=submit] {
		border: none;
		color: var(--inv-color);
		background-color: var(--inv-bgcolor);
	}

	button[type=submit]:hover {
		background-image: linear-gradient(var(--inv-pressed-bgcolor), var(--inv-pressed-bgcolor));
	}
`);

customElements.define('c-login', LoginDialog);

/**
 *
 */
export class ChangePwdDialog extends BaseElement {

	protected _initialize(init: OSkinableInit) {
		const reg = this.findReg<IBasicUniversePointer>(init);
		const sr = this.attachShadow(DOMSH.SHADOWDOM_INIT);
		reg.installSkin("webzone:panel", sr);
		this._initAndInstallSkin('c-login', init);
		const changePwdForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userChangePwdForm')(reg);
		sr.appendChild(changePwdForm);
		const universe = reg.env.universe;
		initRenewPwdForm(changePwdForm, universe.userSelf, universe.auth, {
			renewPwdDone: () => {
				POPUP.findPopupableParent(this).close();
			}
		});
	}
}

customElements.define('c-change-pwd', ChangePwdDialog);

/** @return Form de connexion **embedded** */
REG.reg.registerSvc('userLoginForm', 1, (reg: IReg<IUniverseEnv>) => <form id="loginForm" method="post">
	<section>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="loginAccount">Compte</label>
			<input name="account" id="loginAccount" autocomplete="username" spellcheck="false"/>
			<label for="loginPassword">Mot de passe</label>
			<input name="password" type="password" id="loginPassword" autocomplete="current-password"/>
		</section>
		<div class="footer" id="loginActions">
			<button class="pwdLost" type="button">Mot de passe perdu</button>
			<div class="spacer"/>
			<button type="submit">Se connecter</button>
		</div>
	</section>
</form>);

/**
 * Construction d'une zone de login dans {@param targetElt} en traitant :
 * 		- remoteAuth
 * 		- embeddedAuth : l'appel du svc `userLoginForm` (ou {@param embeddedLoginForm} si spécifié)
 * 	@return embeddedLoginForm si ajouté
 */
REG.reg.registerSvc('fullUserLoginMaker', 1, (reg: IReg<IUniverseEnv & IAuthenticatedEnv>, targetElt: Node, embeddedLoginForm?: HTMLFormElement): HTMLFormElement | null => {
		const appDef = (desk && UrlHashObjDeskFeat.isIn(desk)) ? desk.getUrlHash() : null;
		const lastLoginAppDef: JLoginAppDef<any> = (appDef as JLoginAppDef<any>)?.lastRemoteLoginAttempt ? appDef : null;

		// Remote
		if (reg.env.remoteAuthentications?.length)
			reg.env.remoteAuthentications.forEach((entry) => targetElt.appendChild(entry.redrawLogin(reg, lastLoginAppDef?.authMethod === entry.authMethod ? lastLoginAppDef.lastRemoteLoginAttempt : null)));

		// Embedded
		if (reg.env.embeddedAuthentication !== false && embeddedLoginForm === undefined)
			embeddedLoginForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userLoginForm')(reg);

		if (embeddedLoginForm) {
			let embeddedEltsParent: Node = targetElt;
			if (reg.env.remoteAuthentications?.length) {
				const title = typeof reg.env.embeddedAuthentication === "string" ? reg.env.embeddedAuthentication : 'authentification interne';
				embeddedEltsParent = targetElt.appendChild(<fieldset class="authTypeContainer embedded">
					<legend>{title}</legend>
					{embeddedLoginForm}
				</fieldset>)
			} else
				targetElt.appendChild(embeddedLoginForm);
			return embeddedLoginForm;
		}
	}
);

REG.reg.registerSvc('userRenewPwdForm', 1, (reg: IReg<IUniverseEnv>) => <form id="renewPwdForm" method="post">
	<section>
		<h3>Renouvellement de votre mot de passe</h3>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="renewAccount">Compte</label>
			<input name="account" id="renewAccount" autocomplete="username" spellcheck="false"/>
			<label for="currentPwd">Mot de passe actuel</label>
			<input name="currentPwd" type="password" id="currentPwd" autocomplete="current-password"/>
			<label for="newPwd">Nouveau mot de passe</label>
			<input name="newPwd" type="password" id="newPwd" autocomplete="new-password"/>
			<label for="confirmPwd">Confirmation du mot de passe</label>
			<input name="confirmPwd" type="password" id="confirmPwd" autocomplete="new-password"/>
		</section>
		<div class="footer" id="renewPwdActions">
			<button type="submit">Valider</button>
		</div>
	</section>
</form>);

REG.reg.registerSvc('userChangePwdForm', 1, (reg: IReg<IUniverseEnv>) => <form id="changePwdForm" method="post">
	<section>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="renewAccount">Compte</label>
			<input name="account" id="renewAccount" autocomplete="username" disabled="" spellcheck="false"/>
			<label for="currentPwd">Mot de passe actuel</label>
			<input name="currentPwd" type="password" id="currentPwd" autocomplete="current-password"/>
			<label for="newPwd">Nouveau mot de passe</label>
			<input name="newPwd" type="password" id="newPwd" autocomplete="new-password"/>
			<label for="confirmPwd">Confirmation du mot de passe</label>
			<input name="confirmPwd" type="password" id="confirmPwd" autocomplete="new-password"/>
		</section>
		<div class="footer" id="changePwdActions">
			<button type="submit">Valider</button>
		</div>
	</section>
</form>);

const actionChangePwd = new Action<HTMLElement>("actionChangePwd")
	.setLabel("Modifier le mot de passe...")
	.setVisible(function (ctx: HTMLElement) {
		const reg = REG.findReg<IBasicUniversePointer>(ctx);
		return reg.env.universe.auth.currentAuthenticatedUser !== null;
	})
	.setEnabled(function (ctx: HTMLElement) {
		const reg = REG.findReg<IBasicUniversePointer>(ctx);
		let currentUser = reg.env.universe.auth.currentAuthenticatedUser;
		if (currentUser && (currentUser.isReadOnly || currentUser.isDisabled))
			return false;
	})
	.setExecute(function (ctx: HTMLElement, event: Event) {
		POPUP.showDialog(new ChangePwdDialog(), ctx, {fixSize: false, titleBar: {barLabel: {label: "Changement de votre mot de passe"}}});
	});

REG.reg.registerSvc('actionChangePwd', 1, actionChangePwd);

REG.reg.registerSvc('actionUserLogout', 1, new Action<Element>('actionUserLogout')
	.setGroup("logout")
	.setLabel("Se déconnecter")
	.setVisible((ctx: Element) => {
		const auth = (REG.findReg(ctx).env as IBasicUniversePointer).universe.auth;
		return auth.currentAuthenticatedUser != null;
	})
	.setExecute(async (ctx: Element) => {
		const reg: IReg<IBasicUniversePointer & IAuthenticatedEnv> = REG.findReg(ctx);
		const userSelf = reg.env.universe.userSelf;
		const user = reg.env.universe.auth.currentAuthenticatedUser;
		const remoteAuth = reg.env.remoteAuthentications?.find(entry => entry.authMethod === user.authMethod);
		if (!remoteAuth || remoteAuth && await remoteAuth.logout(reg))
			await userSelf.logout();
	}));

function langSelector(reg: IReg<IUniverseEnv>): HTMLElement | undefined {
	if (!window.desk) return;
	if (UiLangDeskFeat.isIn(desk)) {
		return <div id="langCtn"><ActionBtn id="langBtn" î={{
			reg,
			action: desk.newLangMenu(),
			uiContext: "bar"
		} as OActionBtnInit<HTMLElement>}/></div>;
	}
}
